export default {
  /**
   * 用户管理
   */
  // 黑名单列表
  black_list_add: 'BlackAdd',
  black_list_edit: 'BlackEdit',
  black_list_move: 'black_list_move',
  black_list_record: 'black_list_record',
  black_list_import: 'black_list_import',

  // 灰名单列表
  gray_list_add: 'GrayAdd',
  gray_list_edit: 'GrayEdit',
  gray_list_move: 'gray_list_move',
  gray_list_record: 'gray_list_record',
  gray_list_import: 'gray_list_import',
  // 撞库查询
  hit_list_import: 'hit_list_import',
  //   权限管理
  // 管理员
  user_list_add: 'UserAdd',
  user_list_edit: 'UserEdit',
  user_list_del: 'user_list_del',
  user_list_status: 'user_list_status',
  //   角色
  role_list_add: 'RoleAdd',
  role_list_edit: 'RoleEdit',
  role_list_del: 'role_list_del',
  role_list_menu: 'role_list_menu',
  //   菜单
  menu_list_add: 'MenuAdd',
  menu_list_edit: 'MenuEdit',
  menu_list_del: 'menu_list_del'
}
